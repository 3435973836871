// Chakra imports
import { Avatar, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";

// Custom components
import Card from "components/card/Card.js";
import Information from "views/admin/profile/components/Information";

// Assets
export default function GeneralInformation(props) {
  const [profileData, setProfileData] = useState({});
  const { avatar, ...rest } = props;

  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  useEffect(() => {
    // Fetch data from the API
    axios.get('https://taxbezzie.d10xpoc.xyz/api/v1/userprofile/',{withCredentials:true})
      .then(response => {
        setProfileData(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the profile data!", error);
      });
  }, []);

  return (
    <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
      {/* <Avatar
        mx='auto'
        src={avatar}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      /> */}
      <Text
        color={textColorPrimary}
        fontWeight='bold'
        fontSize='2xl'
        mt='10px'
        mb='4px'>
        General Information
      </Text>
      {/* <Text color={textColorSecondary} fontSize='md' me='26px' mb='40px'> */}
        {/* If the profile data is available, display a summary or a message */}
        {/* {profileData.email ? `Email: ${profileData.email}` : "Loading..."} */}
      {/* </Text> */}
      <SimpleGrid columns='2' gap='20px'>
        <Information
          boxShadow={cardShadow}
          title='First Name'
          value={profileData.first_name || 'N/A'}
        />
        <Information
          boxShadow={cardShadow}
          title='Last Name'
          value={profileData.last_name || 'N/A'}
        />
        <Information
          boxShadow={cardShadow}
          title='Mobile Number'
          value={profileData.mobile_number || 'N/A'}
        />
        <Information
          boxShadow={cardShadow}
          title='Email'
          value={profileData.email || 'N/A'}
        />
      </SimpleGrid>
    </Card>
  );
}
