import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { useState } from 'react';
import AdminLayout from './layouts/admin';
import AuthLayout from './layouts/auth';
import initialTheme from './theme/theme';
import AuthMiddleware from './routes/middleware/AuthMiddleware';

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        {/* Non-authenticated routes */}
        <Route path="auth/*" element={<AuthLayout />} />

        {/* Authenticated routes */}
        {/* {userRoutes.map((route, idx) => (
          <Route
            key={idx}
            path={route.path}
            element={
              <AuthMiddleware>
                <AdminLayout theme={currentTheme} setTheme={setCurrentTheme}>
                  {route.component}
                </AdminLayout>
              </AuthMiddleware>
            }
          />
        ))} */}
        <Route
          path="admin/*"
          element={
          <>
            <AuthMiddleware>
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
            </AuthMiddleware>
          </>
            }
        />

        {/* Redirect to dashboard */}
        <Route path="/" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </ChakraProvider>
  );
}
