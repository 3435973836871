// Chakra imports
import {
  Box,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState, React } from "react";
import axios from 'axios';
// Custom components
import ComplexTable from "./components/ComplexTable";
export default function UserList() {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    // Fetch the data from your API using axios
    axios
      .get('https://taxbezzie.d10xpoc.xyz/api/v1/userlist/', {
        withCredentials: true, // Include cookies if necessary
      })
      .then((response) => {
        setUserList(response.data); // Set the fetched data to state
        setLoading(false); // Set loading to false after data is fetched
      })
      .catch((error) => {
        console.error('Error fetching user list:', error);
        setLoading(false); // Set loading to false even if there is an error
      });
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
     {loading ? ( // Show spinner while loading
        <Spinner size="lg" color="teal.500" /> // Spinner for loading state
      ) : (
        <ComplexTable tableData={userList} /> // Show table only after data is fetched
      )}
    </Box>
  );
}
