import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthMiddleware = ({ children }) => {
  const isAuthenticated =  useSelector((state) => state.auth.isAuthenticated) ;
  const localAuth = localStorage.getItem('email');
  if (localAuth===null) {
    return <Navigate to="/auth/login" replace />;
  }

  return children;
};

export default AuthMiddleware;