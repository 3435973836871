
// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import General from "views/admin/profile/components/General";

// Assets
import avatar from "assets/img/avatars/avatar4.png";
import React from "react";

export default function Overview() {
  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {/* Main Fields */}
      <Grid
        gap={{ base: "20px", xl: "20px" }}>
        
      <General
        gridArea
        minH='365px'
        pe='20px'
        avatar={avatar}
      />
      </Grid>
    </Box>
  );
}
