// Chakra Imports
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Flex, Link, Text, useColorModeValue } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import AdminNavbarLinks from 'components/navbar/NavbarLinksAdmin';
import { MdHome } from 'react-icons/md';
import { Icon } from '@chakra-ui/react';
import { useLocation, Link as RouterLink } from 'react-router-dom'; // Import hooks and Link from react-router-dom

export default function AdminNavbar(props) {
	const [scrolled, setScrolled] = useState(false);
	const location = useLocation(); // Hook to get current location

	useEffect(() => {
		window.addEventListener('scroll', changeNavbar);

		return () => {
			window.removeEventListener('scroll', changeNavbar);
		};
	}, []);

	const { secondary, message, brandText } = props;
	console.log(brandText);

	// Colors
	const mainText = useColorModeValue('navy.700', 'white');
	const secondaryText = useColorModeValue('gray.700', 'white');
	const breadcrumbColor = useColorModeValue('gray.700', 'white');

	// Function to change navbar appearance on scroll
	const changeNavbar = () => {
		if (window.scrollY > 1) {
			setScrolled(true);
		} else {
			setScrolled(false);
		}
	};

	// Breadcrumb logic: Split pathname and dynamically generate breadcrumbs
	const pathnames = location.pathname
  .split('/')
  .filter((segment, index, arr) => arr[index - 1] === 'admin' || arr.includes('admin') && index > arr.indexOf('admin')); // Split path into segments and filter empty strings
	
	return (
		<Box
			position="fixed"
			boxShadow="none"
			bg={useColorModeValue('rgba(244, 247, 254, 0.2)', 'rgba(11,20,55,0.5)')}
			borderColor="transparent"
			filter="none"
			backdropFilter="blur(10px)"
			borderRadius="16px"
			borderWidth="1.5px"
			borderStyle="solid"
			transitionDelay="0s, 0s, 0s, 0s"
			transitionDuration="0.25s, 0.25s, 0.25s, 0s"
			transitionProperty="box-shadow, background-color, filter, border"
			transitionTimingFunction="linear, linear, linear, linear"
			alignItems={{ xl: 'center' }}
			display={secondary ? 'block' : 'flex'}
			minH="75px"
			justifyContent={{ xl: 'center' }}
			lineHeight="25.6px"
			mx="auto"
			mt="0px"
			pb="8px"
			right={{ base: '12px', md: '30px', lg: '30px', xl: '30px' }}
			px={{ sm: '15px', md: '10px' }}
			ps={{ xl: '12px' }}
			pt="8px"
			top={{ base: '12px', md: '16px', lg: '20px', xl: '20px' }}
			w={{
				base: 'calc(100vw - 6%)',
				md: 'calc(100vw - 8%)',
				lg: 'calc(100vw - 6%)',
				xl: 'calc(100vw - 350px)',
				'2xl': 'calc(100vw - 365px)'
			}}
		>
			<Flex w="100%" flexDirection={{ sm: 'column', md: 'row' }} alignItems={{ xl: 'center' }} mb="0px">
				<Box mb={{ sm: '8px', md: '0px' }}>
					<Breadcrumb>
						<BreadcrumbItem color={breadcrumbColor} fontSize="sm" mb="5px">
							<BreadcrumbLink as={RouterLink} to="/" color={breadcrumbColor}>
								<Icon as={MdHome} width="20px" height="20px" color="brand" />
							</BreadcrumbLink>
						</BreadcrumbItem>
						{pathnames.map((value, index) => {
							const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
							const isLast = index === pathnames.length - 1;
							return (
								<BreadcrumbItem key={index} color={breadcrumbColor} fontSize="sm" mb="5px">
									{isLast ? (
										<Text color={breadcrumbColor}>{value}</Text>
									) : (
										<BreadcrumbLink as={RouterLink} to={routeTo} color={breadcrumbColor}>
											{value}
										</BreadcrumbLink>
									)}
								</BreadcrumbItem>
							);
						})}
					</Breadcrumb>
					<Link
						color={mainText}
						bg="inherit"
						borderRadius="inherit"
						fontWeight="bold"
						fontSize="34px"
						_hover={{ color: mainText }}
						_active={{ bg: 'inherit', transform: 'none', borderColor: 'transparent' }}
						_focus={{ boxShadow: 'none' }}
					>
						{brandText}
					</Link>
				</Box>
				<Box ms="auto" w={{ sm: '100%', md: 'unset' }}>
					<AdminNavbarLinks
						onOpen={props.onOpen}
						logoText={props.logoText}
						secondary={props.secondary}
						fixed={props.fixed}
						scrolled={scrolled}
					/>
				</Box>
			</Flex>
			{secondary ? <Text color="white">{message}</Text> : null}
		</Box>
	);
}

AdminNavbar.propTypes = {
	brandText: PropTypes.string,
	variant: PropTypes.string,
	secondary: PropTypes.bool,
	fixed: PropTypes.bool,
	onOpen: PropTypes.func
};
