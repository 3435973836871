import React from 'react';
import ReactDOM from 'react-dom/client'; // Import the correct API for React 18+
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from './store'; // Import your configured Redux store
import App from './App';
import {AuthProvider} from 'contexts/authContext';

// Create a root using the new createRoot API
const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);
