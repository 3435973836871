/* eslint-disable */
import {
    Box,
    Flex,
    Icon,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Tag, // Import Tag component for chips
  } from '@chakra-ui/react';
  import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
  } from '@tanstack/react-table';
  import React from 'react';
  import Card from 'components/card/Card';
  import { MdCancel, MdCheckCircle } from 'react-icons/md';
  
  const columnHelper = createColumnHelper();
  
  export default function ComplexTable({ tableData }) {
    const [sorting, setSorting] = React.useState([]);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  
    // Define table columns
    const columns = [
      columnHelper.accessor('first_name', {
        id: 'first_name',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            NAME
          </Text>
        ),
        cell: (info) => (
          <Flex align="center">
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue() || 'N/A'}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            EMAIL
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        ),
      }),
      columnHelper.accessor('status', {
        id: 'status',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            STATUS
          </Text>
        ),
        cell: (info) => (
          <Flex align="center">
            <Icon
              w="24px"
              h="24px"
              me="5px"
              color={info.getValue() ? 'green.500' : 'red.500'}
              as={info.getValue() ? MdCheckCircle : MdCancel}
            />
            <Text color={textColor} fontSize="sm" fontWeight="700">
              {info.getValue() ? 'Active' : 'Inactive'}
            </Text>
          </Flex>
        ),
      }),
      columnHelper.accessor('invitation', {
        id: 'invitation',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            INVITATION
          </Text>
        ),
        cell: (info) => (
          <Tag
            size="sm"
            variant="solid"
            colorScheme={info.getValue() ? 'green' : 'secondaryGray'}
          >
            {info.getValue() ? 'Invited' : 'Pending'}
          </Tag>
        ),
      }),
      columnHelper.accessor('last_login', {
        id: 'last_login',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            LAST LOGIN
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue() ? new Date(info.getValue()).toLocaleString() : 'Never'}
          </Text>
        ),
      }),
      columnHelper.accessor('date_joined', {
        id: 'date_joined',
        header: () => (
          <Text fontSize={{ sm: '10px', lg: '12px' }} color="gray.400">
            DATE JOINED
          </Text>
        ),
        cell: (info) => (
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {new Date(info.getValue()).toLocaleString()}
          </Text>
        ),
      }),
    ];
  
    const [data, setData] = React.useState(() => [...tableData]);
    const table = useReactTable({
      data,
      columns,
      state: {
        sorting,
      },
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      debugTable: false,
    });
  
    return (
      <Card flexDirection="column" w="100%" px="0px" overflowX={{ sm: 'auto', lg: 'auto' }}>
        <Box>
          <Table variant="simple" color="gray.500" mb="24px" mt="12px">
            <Thead>
              {table && table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <Flex justifyContent="space-between" align="center">
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {{ asc: '', desc: '' }[header.column.getIsSorted()] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table && table.getRowModel().rows.slice(0, 11).map((row) => {
                return (
                  <Tr key={row.id}>
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Td key={cell.id} fontSize={{ sm: '14px' }} minW={{ sm: '150px', md: '200px', lg: 'auto' }} borderColor="transparent">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Card>
    );
  }
  